
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "TheCourseTitleList",
  props: {
    label: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: false,
    },
  },
  data: () => ({}),
  methods: {
    /* can be used for testing purposes, but should be deleted eventually to prevent students from viewing
    course info for courses where they arent påmelt.

    handleClick(value) {
      this.$router.push(`/course/${value}`);
    }*/
  },
});
