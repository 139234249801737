
import { ApiGetCourseParticipantDto } from "@/api/generated/Api";
import TheCourseTitleList from "@/components/courses/list/TheCourseTitleList.vue";
import { sortByCourseStatus } from "@/shared/helpers/arrayHelpers";
import { formatLocalizedDate } from "@/shared/helpers/dateHelpers";
import { useRouter } from "@/shared/useHelpers";
import { defineComponent, PropType } from "@vue/composition-api";

export default defineComponent({
  name: "TheAssignmentTableList",
  components: { TheCourseTitleList },
  props: {
    assignmentList: {
      type: Array as PropType<ApiGetCourseParticipantDto[]>,
      required: true,
    },
    search: {
      type: String,
      required: true,
    },
  },
  setup() {
    const router = useRouter();

    const viewApplication = (item: ApiGetCourseParticipantDto) => {
      if (!item.status) {
        return;
      }
      router.push({
        name: "singleAssignmentCourse",
        params: { id: item.courseId.toString(), userStatus: item.status },
      });
    };

    return {
      viewApplication,
      formatLocalizedDate,
      headers,
    };
  },
});

const headers = [
  {
    text: "Id",
    value: "courseId",
  },
  {
    text: "Handlinger",
    value: "action",
  },
  {
    text: "Tittel",
    value: "course.externalTitle",
  },
  {
    text: "Start",
    value: "course.startDate",
  },
  {
    text: "Slutt",
    value: "course.endDate",
  },
  {
    text: "Status",
    value: "course.status",
    sort: (a: string, b: string) => sortByCourseStatus(a, b),
  },
  {
    text: "Rolle",
    value: "roleName",
  },
  {
    text: "Antall deltakere",
    value: "course.participantSubscribedCount",
  },
];
