
import { api } from "@/api/api";
import { ApiGetCourseParticipantDto } from "@/api/generated/Api";
import TheAssignmentTableList from "@/components/courses/list/TheAssignmentTableList.vue";
import BaseLayout from "@/components/shared/layout/BaseLayout.vue";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { RolesType } from "@/shared/enums/roleTypesEnum.enums";
import { isMobile } from "@/shared/helpers/displayHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { useRouter } from "@/shared/useHelpers";
import { computed, defineComponent, onMounted, ref } from "@vue/composition-api";

enum ActiveAssignmentStatus {
  Open = "åpnet",
  Planned = "planlagt",
}

enum InactiveAssignmentStatus {
  Closed = "avsluttet",
}

export default defineComponent({
  name: "ActiveAssignmentListPage",
  components: { TheAssignmentTableList, BaseLayout },
  props: {
    routeTab: String,
  },
  setup(props) {
    const router = useRouter();
    const search = ref("");
    const activeAssignments = ref<ApiGetCourseParticipantDto[]>();
    const inactiveAssignments = ref<ApiGetCourseParticipantDto[]>();

    const currentTab = computed({
      set: (tab: string) => {
        router.replace({ params: { tab } });
      },
      get: () => props.routeTab || "active",
    });

    const fetctCourses = async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        const response = (await api.course.getCourseParticipantAndCoursesByCurrentUserAsync()).data.filter(
          (item) => item.roleName !== RolesType.Student
        );

        activeAssignments.value = response.filter((x) =>
          Object.values(ActiveAssignmentStatus).includes(x.course?.status as ActiveAssignmentStatus)
        );
        inactiveAssignments.value = response.filter((x) =>
          Object.values(InactiveAssignmentStatus).includes(x.course?.status as InactiveAssignmentStatus)
        );
      });
    };

    onMounted(() => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        await fetctCourses();
      });
    });

    return {
      search,
      currentTab,
      activeAssignments,
      inactiveAssignments,
      isMobile,
    };
  },
});
