var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', {
    staticClass: "mt-2",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.assignmentList,
      "sort-by": ['course.status', 'courseId'],
      "sort-desc": [false, true],
      "search": _vm.search,
      "data-cy": "courseTable"
    },
    scopedSlots: _vm._u([{
      key: "item.course.startDate",
      fn: function (_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s(_vm.formatLocalizedDate(value)) + " ")];
      }
    }, {
      key: "item.course.endDate",
      fn: function (_ref2) {
        var value = _ref2.value;
        return [_vm._v(" " + _vm._s(_vm.formatLocalizedDate(value)) + " ")];
      }
    }, {
      key: "item.action",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.isContractSigned ? _c('v-tooltip', {
          attrs: {
            "top": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref4) {
              var on = _ref4.on;
              return [_c('v-btn', _vm._g({
                attrs: {
                  "data-cy": "viewApplication",
                  "icon": "",
                  "color": "primary"
                },
                on: {
                  "click": function ($event) {
                    return _vm.viewApplication(item);
                  }
                }
              }, on), [_c('v-icon', [_vm._v("mdi-file-document-outline")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Se kurs")])]) : _vm._e()];
      }
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }